@keyframes marquee {
  0%, /* Start with delay 0% */
  25% {
    transform: translateX(0);
  }
  55%, /* Short delay in middle */
  65% {
    transform: translateX(var(--marquee-translate));
  }
  95% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

.marquee {
  animation: marquee 20s linear infinite;
  display: inline-block;
  will-change: transform;
  white-space: nowrap;
}
